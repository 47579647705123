import React, { useState } from 'react';

import classNames from 'clsx';
import FillLayers from '@wix/thunderbolt-elements/src/components/FillLayers/viewer/FillLayers';
import {
  getDataAttributes,
  getTabIndexAttribute,
  debounce,
} from '@wix/editor-elements-common-utils';
import Link from '@wix/thunderbolt-elements/src/components/Link/viewer/Link';
import type { TextMaskProps } from '../TextMask.types';
import styles from './TextMask.scss';
import { TextElement } from './components/TextElement';

function handleResize(container: HTMLElement): {
  fontSize: number;
  viewBox: string;
} {
  const preSvg: SVGSVGElement = container.querySelector('.preview-svg')!;
  const disSvg: SVGSVGElement = container.querySelector('.display-svg')!;
  const texts: NodeListOf<SVGTextElement> = disSvg.querySelectorAll('text')!;

  const fontSize = parseFloat(preSvg?.dataset.fontsize || '0');

  // -- Setup
  // Our testing environment is JSDOM based and does not fully support all SVG methods, specifically getBBox
  const { width = 0, height = 0 } = preSvg?.getBBox?.() ?? {};

  // Don't accept empty measurements
  if (!(width && height)) {
    return { fontSize: 0, viewBox: '0 0 0 0' };
  }

  const containerW = container.offsetWidth;
  const viewBoxRatio = width / height;
  const newViewBoxW = containerW;
  const newViewBoxH = containerW / viewBoxRatio;

  const newViewBox = `0 0 ${newViewBoxW} ${newViewBoxH}`;
  const newFontSize = (newViewBoxW / width) * fontSize;

  // -- Apply Setup
  disSvg.setAttributeNS(null, 'viewBox', newViewBox);

  texts.forEach(textElm => {
    textElm.style.fontSize = newFontSize + 'px';
  });

  container.dataset.maskState = 'true';

  return {
    fontSize: newFontSize,
    viewBox: newViewBox,
  };
}

const TextMask = (props: TextMaskProps) => {
  const {
    id,
    fillLayers,
    isDecorative,
    headingLevel,
    size,
    link,
    className,
    viewBox,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const clipId = `clip-${id}`;
  const textGroupId = `text-group-${id}`;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const a11yProps = React.useMemo(
    () => ({
      'aria-labelledby': !isDecorative ? textGroupId : undefined,
      'aria-hidden': isDecorative || undefined,
    }),
    [isDecorative, textGroupId],
  );
  const [lastViewBox, setViewBox] = useState<string>(
    typeof viewBox === 'string' ? viewBox : '0 0 0 0',
  );
  const [lastProps, setLastProps] = useState<TextMaskProps>({ ...props });

  // font done loading
  React.useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handler = () => {
        const newData = handleResize(container as HTMLDivElement);
        setViewBox(newData.viewBox);
        setLastProps({
          ...props,
          size: newData.fontSize,
        });
      };
      document.fonts?.addEventListener('loadingdone', handler, { once: true });
    }
  }, [props]);

  // Did mount / Will unmount
  React.useEffect(() => {
    const debouncedHandleResize = debounce(
      (elements: Array<HTMLElement>) => {
        for (const element of elements) {
          handleResize(element);
        }
      },
      60,
      { leading: true, trailing: true },
    );

    const resizeObserver =
      typeof ResizeObserver !== 'undefined' &&
      new ResizeObserver(entries =>
        debouncedHandleResize(entries.map(entry => entry.target)),
      );

    const container = containerRef.current;
    if (container && resizeObserver) {
      resizeObserver.observe(container);
    }
    return () => {
      if (container && resizeObserver) {
        resizeObserver.unobserve(container);
      }
    };
  }, []);

  // On every design change
  React.useEffect(() => {
    const container = containerRef?.current;
    if (container) {
      const newData = handleResize(container);

      setViewBox(newData.viewBox);

      setLastProps({
        ...props,
        size: newData.fontSize,
      });
    }
  }, [props]);

  const DisplaySvg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={lastViewBox}
      className={classNames('display-svg', styles.svg, styles.displaySvg)}
      {...a11yProps}
    >
      <defs>
        <clipPath id={clipId} aria-hidden="true">
          <TextElement {...lastProps} />
        </clipPath>
      </defs>
      <g className="text-group" id={textGroupId}>
        <TextElement {...lastProps} extraStyle={{ overflow: 'visible' }} />
      </g>
    </svg>
  );

  const PreviewSvg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('preview-svg', styles.svg, styles.previewSvg)}
      data-fontsize={size}
      aria-hidden="true"
    >
      <TextElement {...props} />
    </svg>
  );

  const HeadingLevelTag = headingLevel
    ? (`h${headingLevel}` as keyof JSX.IntrinsicElements)
    : 'p';

  const DisplaySvgWithHeading = isDecorative
    ? DisplaySvg
    : () => (
        <HeadingLevelTag>
          <DisplaySvg />
        </HeadingLevelTag>
      );

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      {...getTabIndexAttribute(props.a11y)}
      className={classNames(styles.root, className)}
      ref={containerRef}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.designLayer}>
        <PreviewSvg />

        {link && Object.keys(link).length ? (
          <Link {...link}>
            <DisplaySvgWithHeading />
          </Link>
        ) : (
          <DisplaySvgWithHeading />
        )}

        <div
          className={classNames(
            styles.fillLayersWrapper,
            'fill-layers-wrapper',
          )}
          style={{ clipPath: `url(#${clipId})` }}
        >
          <FillLayers {...fillLayers} />
        </div>
      </div>
    </div>
  );
};

export default TextMask;
